import { useEffect, useState, useMemo } from "react";
import { getSecure } from "../utils/http";
import { useTable, usePagination, useFilters, useSortBy } from "react-table";
import toast from "react-hot-toast";

function BackofficeParticipants() {
  const [participants, setParticipants] = useState([]);

  useEffect(() => {
    getSecure("/users")
      .then(res => {
        setParticipants(res);
      })
      .catch(err => {
        console.log(err);
        toast.error(err);
      });
  }, []);

  const data = useMemo(() => participants, [participants]);

  const columns = useMemo(
    () => [
      {
        Header: "Data / Laikas",
        accessor: row => new Date(row.createdAt).toLocaleString("lt-LT"),
      },
      {
        Header: "Vardas",
        accessor: "name",
        Filter: TextColumnFilter,
      },
      {
        Header: "Dalyvių skaičius",
        accessor: "participantCount",
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,

    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    { columns, data, initialState: { pageSize: 30 } },
    useFilters,
    // useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <div>
      <h2>Dalyviai</h2>

      <table {...getTableProps()} className="questionsTable">
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => {
                return (
                  <th {...column.getHeaderProps()}>
                    <div
                      {...column.getSortByToggleProps()}
                      style={{
                        cursor: "pointer",
                        height: 30,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {column.render("Header")}
                      <div style={{ width: 20 }}>{column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}</div>
                    </div>

                    {/* Render the columns filter UI */}
                    <div>{column.canFilter && column.Filter ? column.render("Filter") : null}</div>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>

      <div className="pagination">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {"<<"}
        </button>{" "}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {"<"}
        </button>{" "}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {">"}
        </button>{" "}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {">>"}
        </button>{" "}
        <span>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
        <span>
          | Go to page:{" "}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            style={{ width: "100px" }}
          />
        </span>{" "}
        <select
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}

export default BackofficeParticipants;

const TextColumnFilter = ({ column }) => {
  const { filterValue, setFilter, preFilteredRows } = column;

  const count = preFilteredRows.length;

  return (
    <span>
      <input placeholder={`${count} records...`} value={filterValue || ""} onChange={e => setFilter(e.target.value)} />
    </span>
  );
};

import logo from "../img/mokonomika@2x.png";
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import BackofficeParticipants from "./BackofficeParticipants";
import BackofficeQuestions from "./BackofficeQuestions";
import BackofficeVideoLinks from "./BackoficeVideoLinks";

function BackofficeHome() {
  const navigate = useNavigate();

  return (
    <div className="appWrapper backofficeWrapper">
      <div className="app">
        <div className="sideBlock">
          <div className="logoBlock">
            <img src={logo} className="appLogo" alt="logo" />
          </div>
          <div className="menuBlock">
            <Menu />
          </div>
        </div>
        <div className="mainBlock">
          <div className="topBlock">
            <div
              className="backofficeLogout"
              onClick={() => {
                localStorage.removeItem("mokonomika_access_token");
                navigate("/backoffice");
              }}
            >
              Logout
            </div>
          </div>
          <div className="contentBlock">
            <Routes>
              <Route path="questions" element={<BackofficeQuestions />} />
              <Route path="video-links" element={<BackofficeVideoLinks />} />
              <Route path="participants" element={<BackofficeParticipants />} />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BackofficeHome;

function Menu() {
  return (
    <>
      <Link to="questions">
        <div className="menuItem helpColor">Klausimai</div>
      </Link>
      <Link to="video-links">
        <div className="menuItem helpColor">Video nuorodos</div>
      </Link>
      <Link to="participants">
        <div className="menuItem helpColor">Dalyviai</div>
      </Link>
    </>
  );
}

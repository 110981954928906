import { useState } from "react";
import { Modal } from "react-responsive-modal";
import { post } from "../utils/http";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

function Login() {
  const navigate = useNavigate();

  const accessTokenStored = localStorage.getItem("mokonomika_access_token");

  const [modalIsOpen, setIsOpen] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  if (accessTokenStored) return <></>;

  const closeModal = () => setIsOpen(false);

  const save = () => {
    return post("/auth/backoffice/login", { email, password })
      .then(res => {
        if (!res.accessToken) {
          toast.error("Login unsuccessful");
        }

        localStorage.setItem("mokonomika_access_token", res.accessToken);
        closeModal();
        navigate(0);
      })
      .catch(err => {
        console.log(err);
        toast.error("Login unsuccessful");
      });
  };

  return (
    <Modal open={modalIsOpen} center showCloseIcon={false}>
      <div className="userForm">
        <input type="text" placeholder="Email" value={email} onChange={e => setEmail(e.target.value)} />
        <input type="password" placeholder="Password" value={password} onChange={e => setPassword(e.target.value)} />
        {error && <div className="error">{error}</div>}
        <div
          className="goButton"
          onClick={() => {
            if (!email || email.trim() === "") return setError("Email būtinas!");
            if (!password || password.trim() === "") return setError("Slaptažodis būtinas");
            save();
          }}
        >
          Prisijungti
        </div>
      </div>
    </Modal>
  );
}

export default Login;

import logo from "./img/mokonomika@2x.png";
import swedlogo from "./img/swedbank-logo.svg";
import programa from "./img/mokonomika-programa-2024.png";
import programaSmall from "./img/mokonomika-programa-2024.png";

import "./App.css";
import "react-responsive-modal/styles.css";
import { useState, useEffect } from "react";
import { Routes, Route, Link } from "react-router-dom";
import UserFormModal from "./UserFormModal";
import VideoPage from "./VideoPage";
import { Modal } from "react-responsive-modal";
import { classroomTypes } from "./utils/constants";
import { get } from "./utils/http";

function App() {
  const [programOpen, setProgramOpen] = useState(false);
  const [helpOpen, setHelpOpen] = useState(false);

  const [discovererLink, setDiscovererLink] = useState("");
  const [creatorLink, setCreatorLink] = useState("");
  const [expertLink, setExpertLink] = useState("");

  const [activeItem, setActiveItem] = useState(null);

  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const { width } = useWindowDimensions();

  const isMobile = width < 1023;

  useEffect(() => {
    get("/questions/videoLinks")
      .then(res => {
        setDiscovererLink(res.discovererLink);
        setCreatorLink(res.creatorLink);
        setExpertLink(res.expertLink);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  return (
    <div className="appWrapper">
      <div className="app">
        {!isMobile && (
          <SideBlock
            setHelpOpen={setHelpOpen}
            setProgramOpen={setProgramOpen}
            activeItem={activeItem}
            setActiveItem={setActiveItem}
          />
        )}

        <div className="mainBlock">
          <div className="topBlock">
            {isMobile && (
              <div
                className="burger"
                onClick={() => {
                  setShowMobileMenu(true);
                }}
              >
                <div className="box-shadow-menu"></div>
                <div className="menuText">Meniu</div>
              </div>
            )}
            <span className="preSwedLogo">Inicijuoja:</span> <img src={swedlogo} className="swedLogo" alt="swedLogo" />
          </div>
          <div className="contentBlock">
            <Routes>
              <Route index element={<Index setProgramOpen={setProgramOpen} />} />
              <Route
                path="atradejai"
                element={<VideoPage videoURL={discovererLink} classroom={classroomTypes.DISCOVERER} />}
              />
              <Route path="kurejai" element={<VideoPage videoURL={creatorLink} classroom={classroomTypes.CREATOR} />} />
              <Route path="ekspertai" element={<VideoPage videoURL={expertLink} classroom={classroomTypes.EXPERT} />} />

              {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
              <Route path="*" element={<Index setProgramOpen={setProgramOpen} />} />
            </Routes>
          </div>
        </div>
      </div>

      <UserFormModal />

      <Modal
        open={helpOpen}
        onClose={() => {
          setHelpOpen(false);
        }}
        center
      >
        <div className="helpText">
          Jeigu reikia pagalbos — susisiekite:
          <br />
          <a href="mailto:mokonomika@gmail.com">mokonomika@gmail.com</a>
          <br />
          <a href="tel:+37063795615">+37063795615</a>
        </div>
      </Modal>

      <Modal
        open={programOpen}
        center
        onClose={() => setProgramOpen(false)}
        classNames={{
          modal: "customModal",
        }}
      >
        <img src={programa} className="programModal" alt="" />
      </Modal>

      <MobileMenu
        setHelpOpen={setHelpOpen}
        setProgramOpen={setProgramOpen}
        activeItem={activeItem}
        setActiveItem={setActiveItem}
        showMobileMenu={showMobileMenu}
        setShowMobileMenu={setShowMobileMenu}
      />

      {/* <CookieConsent /> */}
    </div>
  );
}

export default App;

function SideBlock({ setHelpOpen, setProgramOpen, activeItem, setActiveItem, showMobileMenu, setShowMobileMenu }) {
  return (
    <div className="sideBlock">
      <div className="logoBlock">
        <img src={logo} className="appLogo" alt="logo" />
      </div>
      <div className="menuBlock">
        <Menu
          setHelpOpen={setHelpOpen}
          setProgramOpen={setProgramOpen}
          activeItem={activeItem}
          setActiveItem={setActiveItem}
          showMobileMenu={showMobileMenu}
          setShowMobileMenu={setShowMobileMenu}
        />
      </div>
    </div>
  );
}

function Menu({ setHelpOpen, setProgramOpen, activeItem, setActiveItem, setShowMobileMenu, showMobileMenu }) {
  const onClickAction = active => {
    setActiveItem(active);
    if (setShowMobileMenu) setShowMobileMenu(false);
  };
  return (
    <>
      <Link to="atradejai">
        <div
          className={`menuItem inventorsColor ${
            activeItem === "atradejai" && !showMobileMenu ? "menuItemActive" : null
          }`}
          onClick={() => {
            onClickAction("atradejai");
          }}
        >
          Atradėjai <span className="menuSmallFont">(1 - 4 klasėms)</span>
        </div>
      </Link>
      <Link to="kurejai">
        <div
          className={`menuItem creatorsColor ${activeItem === "kurejai" && !showMobileMenu ? "menuItemActive" : null}`}
          onClick={() => {
            onClickAction("kurejai");
          }}
        >
          Kūrėjai <span className="menuSmallFont">(5 - 8 klasėms)</span>
        </div>
      </Link>
      <Link to="ekspertai">
        <div
          className={`menuItem expertsColor ${activeItem === "ekspertai" && !showMobileMenu ? "menuItemActive" : null}`}
          onClick={() => {
            onClickAction("ekspertai");
          }}
        >
          Ekspertai <span className="menuSmallFont">(9 - 12 klasėms)</span>
        </div>
      </Link>
      <div
        className={`menuItem programColor`}
        onClick={() => {
          setProgramOpen(true);
          if (setShowMobileMenu) setShowMobileMenu(false);
        }}
      >
        Tvarkaraštis
      </div>
      <div
        className={`menuItem helpColor`}
        onClick={() => {
          setHelpOpen(true);
          if (setShowMobileMenu) setShowMobileMenu(false);
        }}
      >
        Pagalba
      </div>
    </>
  );
}

function Index({ setProgramOpen }) {
  return <img src={programaSmall} className="programa" alt="programa" onClick={() => setProgramOpen(true)} />;
}

function MobileMenu({ setHelpOpen, setProgramOpen, activeItem, setActiveItem, setShowMobileMenu, showMobileMenu }) {
  return (
    <div className={`mobileMenuWrapper ${showMobileMenu ? "coolclass" : ""}`}>
      <div
        className="closeMobileMenu"
        onClick={() => {
          setShowMobileMenu(false);
        }}
      >
        x
      </div>
      <SideBlock
        setHelpOpen={setHelpOpen}
        setProgramOpen={setProgramOpen}
        activeItem={activeItem}
        setActiveItem={setActiveItem}
        showMobileMenu={showMobileMenu}
        setShowMobileMenu={setShowMobileMenu}
      />
    </div>
  );
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

import axios from "axios";

axios.defaults.baseURL = `${process.env.REACT_APP_BACKEND_URL}/api`;

const selectData = response => response.data;

export const get = url => axios.get(url, getConfig()).then(selectData);
export const post = (url, data) => axios.post(url, data, getConfig()).then(selectData);
export const put = (url, data) => axios.put(url, data, getConfig()).then(selectData);
export const del = url => axios.delete(url, getConfig()).then(selectData);

export const getSecure = url => axios.get(url, getConfigSecure()).then(selectData);
export const postSecure = (url, data) => axios.post(url, data, getConfigSecure()).then(selectData);
export const putSecure = (url, data) => axios.put(url, data, getConfigSecure()).then(selectData);
export const delSecure = url => axios.delete(url, getConfigSecure()).then(selectData);
export const getFileSecure = url =>
  axios({ method: "get", url: url, responseType: "arraybuffer", headers: getPlainConfigSecure() });
export const postFileSecure = (url, data) => axios.post(url, data, getFileConfigSecure()).then(selectData);

const getConfig = () => {
  const headers = {};
  return { headers: headers };
};

const getConfigSecure = () => {
  const headers = {
    "x-access-token": localStorage.getItem("mokonomika_access_token"),
    Accept: "application/json",
  };
  return { headers: headers };
};

const getFileConfigSecure = () => ({
  headers: {
    "x-access-token": localStorage.getItem("mokonomika_access_token"),
    "Content-Type": "multipart/form-data",
  },
});

const getPlainConfigSecure = () => ({
  "x-access-token": localStorage.getItem("mokonomika_access_token"),
});

import React, { useEffect } from "react";
import AuthLoginForm from "./AuthLoginForm";
import { useNavigate } from "react-router-dom";
import { post } from "../utils/http";

const AuthProvider = ({ appContent }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const accessToken = localStorage.getItem("mokonomika_access_token");

    if (accessToken) {
      post("/auth/backoffice/validateToken", { accessToken: accessToken })
        .then(res => {
          // valid, do nothing
        })
        .catch(err => {
          //if token is not valid/too old - show login form
          localStorage.removeItem("mokonomika_access_token");
          navigate("/backoffice");
        });
    } else {
      localStorage.removeItem("mokonomika_access_token");
      navigate("/backoffice");
    }
  }, [navigate]);

  if (localStorage.getItem("mokonomika_access_token")) return appContent;
  else return <AuthLoginForm />;
};

export default AuthProvider;

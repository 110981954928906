import { useEffect } from "react";
import ReactPlayer from "react-player/lazy";
import userAvatar from "./img/userImage.png";
import { useState } from "react";
import { post } from "./utils/http";
import toast from "react-hot-toast";

function VideoPage({ videoURL, classroom }) {
  const [question, setQuestion] = useState("");

  const name = localStorage.getItem("mokonomika_user_name");
  const participantCount = localStorage.getItem("mokonomika_participant_count");

  useEffect(() => {
    return () => {
      setQuestion("");
    };
  }, []);

  const sendQuestion = () => {
    return post("/questions", { text: question, classroom, name, participantCount })
      .then(res => {
        if (res.success) {
          setQuestion("");
          toast("Klausimas priimtas. Stengsimės į jį atsakyti kuo greičiau");
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <div className="videoWrapper">
      <div className="playerWrapper">
        <ReactPlayer url={videoURL} className="reactPlayer" width="100%" height="100%" controls />
      </div>
      <div className="questionBlock">
        <div className="userAvatar">
          <img src={userAvatar} className="" alt="logo" />
        </div>
        <div className="middleBlock">
          <div className="userName">{name}</div>
          <div className="inputWrapper">
            <input
              type="text"
              placeholder="Klausimo tekstas"
              value={question}
              onChange={e => setQuestion(e.target.value)}
            />
          </div>
        </div>
        <div className="buttonBlock">
          <div
            className="sendButton"
            onClick={() => {
              if (!question || question.trim() === "") return toast.error("Įveskite klausimo tekstą!");
              sendQuestion();
            }}
          >
            Siųsti
          </div>
        </div>
      </div>
    </div>
  );
}

export default VideoPage;

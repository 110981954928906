import { useState, useEffect } from "react";
import { get, postSecure } from "../utils/http";
// import { classroomTypes } from "../utils/constants";
import toast from "react-hot-toast";

function BackofficeVideoLinks() {
  const [discovererLink, setDiscovererLink] = useState("");
  const [creatorLink, setCreatorLink] = useState("");
  const [expertLink, setExpertLink] = useState("");

  useEffect(() => {
    get("/questions/videoLinks")
      .then(res => {
        setDiscovererLink(res.discovererLink);
        setCreatorLink(res.creatorLink);
        setExpertLink(res.expertLink);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  const save = () => {
    return postSecure("/questions/updateVideoLinks", { discovererLink, creatorLink, expertLink })
      .then(res => {
        setDiscovererLink(res.discovererLink);
        setCreatorLink(res.creatorLink);
        setExpertLink(res.expertLink);
        toast("Nuorodos sėkmingai išsaugotos!");
      })
      .catch(err => {
        console.log(err);
        toast.error(err);
      });
  };

  return (
    <div className="videoLinkPage">
      <h2>Video nuorodos</h2>
      <div>
        <label htmlFor="discovererLink">Atradėjai (1 - 4 klasėms)</label>
        <br />
        <input
          type="text"
          id="discovererLink"
          value={discovererLink}
          onChange={e => setDiscovererLink(e.target.value)}
        />
      </div>
      <div>
        <label htmlFor="creatorLink">Kūrėjai (5 - 8 klasėms)</label>
        <br />
        <input type="text" id="creatorLink" value={creatorLink} onChange={e => setCreatorLink(e.target.value)} />
      </div>
      <div>
        <label htmlFor="expertLink">Ekspertai (9 - 12 klasėms)</label>
        <br />
        <input type="text" id="expertLink" value={expertLink} onChange={e => setExpertLink(e.target.value)} />
      </div>

      <div
        className="goButton"
        onClick={() => {
          if (!discovererLink || discovererLink.trim() === "") return toast.error("Įveskite atradėjo nuorodą!");
          if (!creatorLink || creatorLink.trim() === "") return toast.error("Įveskite kūrėjo nuorodą!");
          if (!expertLink || expertLink.trim() === "") return toast.error("Įveskite eksperto nuorodą!");

          save();
        }}
      >
        Saugoti
      </div>
    </div>
  );
}

export default BackofficeVideoLinks;

import { useState } from "react";
// import Modal from "react-modal";
import { Modal } from "react-responsive-modal";
import { post } from "./utils/http";
import toast from "react-hot-toast";

function UserFormModal() {
  const userNameStored = localStorage.getItem("mokonomika_user_name");
  const participantCountStored = localStorage.getItem("mokonomika_participant_count");

  const [modalIsOpen, setIsOpen] = useState(true);
  const [name, setName] = useState("");
  const [participantCount, setParticipantCount] = useState("");
  const [error, setError] = useState(null);

  if (userNameStored && participantCountStored) return <></>;

  const closeModal = () => setIsOpen(false);

  const save = () => {
    return post("/users", { name, participantCount })
      .then(res => {
        if (res.success) {
          localStorage.setItem("mokonomika_user_name", name);
          localStorage.setItem("mokonomika_participant_count", participantCount);
          closeModal();
          toast("Sveiki atvykę! Linkime geros pamokos!");
        } else {
          toast.error("Nepavyko. Bandykite dar kartą! errorCode=12");
        }
      })
      .catch(err => {
        console.log(err);
        toast.error("Nepavyko. Bandykite dar kartą! errorCode=13");
      });
  };

  return (
    <Modal open={modalIsOpen} center showCloseIcon={false}>
      <div className="userForm">
        <input type="text" placeholder="Vardas*" value={name} onChange={e => setName(e.target.value)} />
        <input
          type="number"
          placeholder="Kiek dalyvių Mokonomiką žiūrės kartu su jumis?*"
          value={participantCount}
          onChange={e => setParticipantCount(e.target.value)}
        />
        {error && <div className="error">{error}</div>}
        <div
          className="goButton"
          onClick={() => {
            if (!name || name.trim() === "") return setError("Įveskite savo pilną vardą!");
            if (!participantCount || isNaN(participantCount) || participantCount === 0)
              return setError("Dalyvių skaičius būtinas");
            if (participantCount < 1 || participantCount > 99) return setError("Dalyvių gali būti nuo 1 iki 99");
            save();
          }}
        >
          Pirmyn!
        </div>
      </div>
    </Modal>
  );
}

export default UserFormModal;

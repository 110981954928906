import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.css";
import App from "./App";
import BackofficeHome from "./backoffice/BackofficeHome";
import { Toaster } from "react-hot-toast";
import AuthProvider from "./backoffice/AuthProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/backoffice/*" element={<AuthProvider appContent={<BackofficeHome />} />} />
      <Route path="*" element={<App />} />
    </Routes>
    <Toaster
      position="bottom-center"
      toastOptions={{
        duration: 5000,
        style: {
          background: "#ad5596",
          color: "#fff",
        },
        error: {
          style: {
            background: "red",
          },
        },
      }}
    />
  </BrowserRouter>
);
